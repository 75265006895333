.skills {
    background-color: white;
}

.header{
    display: flex;
    justify-content: center;
}

.skill_items{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px
}

.skill_item{
    height: 20vh;
    width: 20vw;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.skill_image{
    height:70%
}
