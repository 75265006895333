.container-responsive{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}
 
.iframe-responsive{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}