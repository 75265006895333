.about {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3{
        margin: 3em;
        align-content: center;
        font-size: xx-large;
    }
}