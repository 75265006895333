@import "../../global.scss";

.portfolioList {
  font-size: 14px;
  margin-right: 50px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  @include mobile{
    margin-right: 20px;
  }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}
.demo{
    display: flex;
    justify-content: center;
}


.dinkupp_img{
    height: 70%;
    width: 70%;
    @include mobile{
        height: 100%;
        width: 100%;
      }
 }

.centered{
    text-align: center
}